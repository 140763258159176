export const trackEvent = (category, action, label, value) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            'event': 'd3rEvent',
            'd3rEvent': {
                category,
                action,
                label,
                value
            }
        })
    }
}
